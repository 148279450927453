import axios from "axios";

export default class RedirectService {
  request(params) {
    return new Promise((resolve, reject) => {
      let baseUrl = "";
      if (window.location.hostname === "localhost") {
        baseUrl = "10.1.70.120:3001/unidadesAtendente/" + params;
      } else {
        baseUrl = window.location.protocol + "//" + window.location.hostname + "/unidadesAtendente/" + params;
      }
      axios({
        method: "get",
        url: baseUrl,
        //data: params,
        headers: { "Content-Type": "text/plain;charset=UTF-8" }
      })
        .then(resp => {
          console.log(resp);
          if (resp.status === 200) {
            resolve(resp.data);
          } else {
            reject(resp.statusText);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  getUnidadesAtendente(login) {
    return this.request(login);
  }

}
