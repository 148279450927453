import React, {Component} from 'react';
import RedirectService from "./service/RedirectService";
import {Button, Form, Card} from "react-bootstrap";

class App extends Component {
  redirectService = new RedirectService();

  state = {
    login: "",
    erro: "",
    isLoading: false,
    redirect: false,
    unidades: [],
    unidadesFiltradas: [],
    timer: 5
  };

  componentDidMount() {
    if (localStorage.getItem("url")) {
      this.setState({redirect: true});
      this.startTimer();
    }
  }

  onCancelarRedirectClick() {
    localStorage.clear();
    this.setState({redirect: false, timer: 5});
    clearInterval(this.interval);
  }

  startTimer() {
    this.interval = setInterval(() => {
      this.setState({timer: this.state.timer - 1});
      if ((this.state.timer === 0) && (this.state.redirect)) {
        this.onConfirmarRedirectClick();
      }
    }, 1000);
  }

  onConfirmarRedirectClick() {
    window.location.replace(localStorage.getItem("url"));
  }

  onLoginChange(event) {
    this.setState({login: event.target.value});
  }

  onCarregarClick() {
    if (this.state.login) {
      this.setState({isLoading: true});
      this.redirectService.getUnidadesAtendente(this.state.login).then(resp => {
        if (resp) {
          if (resp.filter(unidade => unidade.url ? true : false).length === 1) {
            localStorage.setItem("url", resp[0].url);
            window.location.replace(resp[0].url);
          } else {
            resp.map(unidade => {
              unidade.filtro = "";
              Object.keys(unidade).forEach(key => {
                if ((key !== "url") && (unidade[key])) {
                  unidade.filtro += unidade[key].toUpperCase();
                }
              });
              return unidade;
            });
            this.setState({unidades: resp, unidadesFiltradas: resp});
          }
        } else {
          this.setState({erro: "Login não encontrado.", isLoading: false});
        }
      });
    } else {
      this.setState({erro: "Favor informar o login.", isLoading: false});
    }
  }

  onUnidadeClick(unidade) {
    localStorage.setItem("url", unidade.url);
    localStorage.setItem("unidade", unidade.unidade);
    this.setState({redirect: true});
    this.startTimer();
  }

  onFiltrarUnidadeChange(event) {
    if (event.target.value) {
      this.setState({unidadesFiltradas: this.state.unidades.filter(unidade => unidade.filtro.indexOf(event.target.value.toUpperCase()) !== -1)});
    } else {
      this.setState({unidadesFiltradas: this.state.unidades});
    }
  }

  render() {
    const styles = {
      divGeral: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        background: "#fafafa"
      },
      logo: {
        width: "200px", marginTop: 10, marginBottom: 50
      },
      titleInfo: {
        fontWeight: "bold", marginRight: "5px"
      }
    };

    return (
      <div style={styles.divGeral}>
        <img src={require('./logo.png')} style={styles.logo} alt="logo"/>
        {
          this.state.redirect ?
            <div style={{display: "flex", flexDirection: "column"}}>
              <h1 style={{textAlign: "center"}}>Você será redirecionado para a unidade: </h1>
              <h1 style={{textAlign: "center"}}>{localStorage.getItem("unidade")}</h1>
              <div style={{display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 20}}>
                <Button className="btn btn-danger btn-lg" onClick={this.onCancelarRedirectClick.bind(this)} style={{width: "30%", margin: 5}}
                >Cancelar</Button>
                <Button className="btn btn-success btn-lg" onClick={this.onConfirmarRedirectClick.bind(this)} style={{width: "30%", margin: 5}}
                >Confirmar <span className="badge badge-light">{this.state.timer}</span></Button>
              </div>
            </div>
            :
            this.state.unidades.length === 0 ?
              <div style={{display: "flex", flexDirection: "column"}}>
                {this.state.erro ?
                  <div className="alert alert-danger" role="alert">
                    {this.state.erro}
                  </div> : <div></div>
                }

                <Form.Label>Digite seu login:</Form.Label>
                <div style={{display: "flex"}}>
                  <Form.Control autoFocus placeholder="Login" onChange={event => this.onLoginChange(event)}
                                style={{marginRight: "5px"}} onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      this.onCarregarClick();
                      ev.preventDefault();
                    }
                  }}/>
                  <Button onClick={this.onCarregarClick.bind(this)} style={{width: "50%"}}
                          disabled={this.state.isLoading}>Carregar</Button>
                  {
                    this.state.isLoading ?
                      <div className="d-flex align-items-center">
                        <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                      </div> : <div></div>
                  }

                </div>

              </div>
              :
              <div style={{display: "flex", flexDirection: "column", alignItems: "center", width: "100%"}}>
                <Form.Label style={{fontSize: "30px"}}>Escolha sua unidade:</Form.Label>
                <Form.Control autoFocus style={{width: "280px"}} placeholder="Filtrar unidade"
                              onChange={event => this.onFiltrarUnidadeChange(event)}/>
                <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", width: "100%"}}>
                  {this.state.unidadesFiltradas.filter(unidade => unidade.url ? true : false).map(unidade => {
                    return (
                      <Card onClick={() => this.onUnidadeClick(unidade)} key={unidade.codigo} className="shadow-sm"
                            style={{width: "20%", margin: 5, cursor: "pointer"}}>
                        <Card.Body>
                          <Card.Title style={{textAlign: "center"}}>{unidade.unidade}</Card.Title>
                          <div style={{display: "flex", flexDirection: "column"}}>
                            <div>
                              <label style={styles.titleInfo}>Empresa:</label>
                              <label>{unidade.empresa}</label>
                            </div>
                            <div>
                              <label style={styles.titleInfo}>Cidade:</label>
                              <label>{unidade.cidade}</label>
                            </div>
                            <div>
                              <label style={styles.titleInfo}>Regional:</label>
                              <label>{unidade.regional}</label>
                            </div>
                            <div>
                              <label style={styles.titleInfo}>Estado:</label>
                              <label>{unidade.estado}</label>
                            </div>
                            <div>
                              <label style={styles.titleInfo}>País:</label>
                              <label>{unidade.pais}</label>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    );
                  })}
                </div>
              </div>
        }
      </div>);
  }
}

export default App;
